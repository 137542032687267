import React, { useState, useEffect }               from 'react';
import { navigate }                                 from 'gatsby';
import { FaUserCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import ReactTooltip                                 from 'react-tooltip';
import { Breakpoint }                               from 'react-socks';


import Link from '@interness/web-core/src/components/elements/Link/Link';

import * as S from './styles';

const Profile = ({ currentUser, handleLogout }) => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [userCanEdit, setUserCanEdit] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setUserCanEdit(currentUser.role.toString() === '1' || currentUser.role.toString() === '3');
    }
  }, [currentUser]);


  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  const onClickLogout = () => {
    handleLogout(() => navigate('/mitgliederbereich'));
    toggleUserMenu();
  };

  return (
    <S.Profile menuOpen={userMenuOpen}>
      <button onClick={toggleUserMenu} className={'int-icon-group'}
              data-tip={`${currentUser.first_name} ${currentUser.last_name}, ${currentUser.member_number}`}>
        <FaUserCircle className={'int-user'}/>
        {userMenuOpen
          ? <FaChevronUp/>
          : <FaChevronDown/>
        }
        <Breakpoint large up>
          <ReactTooltip/>
        </Breakpoint>
      </button>
      <ul>
        {userCanEdit && <li><Link to={`${process.env.GATSBY_API_ENDPOINT}/admin/#/login?project=145`}
                                  onClick={toggleUserMenu}>Administration</Link></li>}
        {/*<li><Link to={'/mitgliederbereich/profil'} onClick={toggleUserMenu}>Profil</Link></li>*/}
        <li>
          <button onClick={onClickLogout}>Abmelden</button>
        </li>
      </ul>
    </S.Profile>
  )
};

export default Profile;