import React, { useState, useEffect } from 'react';
import { Helmet }                     from 'react-helmet';
import DirectusSDK                    from '@directus/sdk-js';

export const ApiContext = React.createContext({});

const CHECK_INITIAL_AUTH = process.env.GATSBY_CHECK_INITIAL_AUTH;
const LOCAL_STORAGE_KEY = 'intUsersAddonProject';
let PROJECT_NAME;

const getAndSetProjectName = () => {
  if (!PROJECT_NAME) {
    if (!process.env.GATSBY_API_PROJECT) {
      let sessionProject;
      if (typeof window !== 'undefined') {
        sessionProject = window.sessionStorage.getItem(LOCAL_STORAGE_KEY);
      }
      if (sessionProject) {
        PROJECT_NAME = sessionProject;
        return sessionProject;
      }
    } else {
      PROJECT_NAME = process.env.GATSBY_API_PROJECT;
      return process.env.GATSBY_API_PROJECT;
    }
  } else {
    return PROJECT_NAME;
  }
};

let client;

if (typeof window !== 'undefined') {
  client = new DirectusSDK({
    url: process.env.GATSBY_API_ENDPOINT,
    project: getAndSetProjectName(),
    mode: process.env.GATSBY_AUTH_MODE || 'jwt',
    persist: true,
    storage: window.sessionStorage,
  });
}

export const ApiProvider = ({ children }) => {
  const [projectName, setProjectName] = useState();
  const [projectDetails, setProjectDetails] = useState();

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  const fetchProjectDetails = async () => {
    const projectDetailsRequest = await fetch(`${process.env.GATSBY_API_ENDPOINT}/${projectName}/`);
    const projectJson = await projectDetailsRequest.json();
    setProjectDetails(projectJson.data.api);
  };

  useEffect(() => {

      const checkAuth = async () => {
        try {
          client.getMe().then(me => {
            if (!currentUser || currentUser.id !== me.data.id) {
              // Apprently new user
              setCurrentUser(me.data);
            }
            setLoggedIn(!!me);
            setAuthChecked(true);
          }).catch(e => {
            console.log('Currently not logged in.', e);
            setCurrentUser(null);
            setLoggedIn(false);
            setAuthChecked(true);
          });
        } catch (e) {
          console.log('Api does not respond :( ', e);
          return false
        }
      };

      if (PROJECT_NAME) {
        if (!authChecked) {
          if (CHECK_INITIAL_AUTH) {
            checkAuth();
          }
        }
      } else {
        setProjectName(getAndSetProjectName())
      }

      if (projectName) {
        fetchProjectDetails();
      } else {
        setProjectName(getAndSetProjectName())
      }
    },
    //eslint-disable-next-line
    [projectName]);

  const handleLogin = async ({ email, password, project, persist = true }) => {
    if (typeof window !== 'undefined') {
      if (project) {
        PROJECT_NAME = project;
      }
      let user = {};
      client.config.persist = persist;
      try {
        user = await client.login({
          email,
          password,
          project: project ? project : process.env.GATSBY_API_PROJECT,
        });
        if (user.data) {
          setCurrentUser(user.data.user);
          setLoggedIn(true);
          window.sessionStorage.setItem(LOCAL_STORAGE_KEY, project ? project : process.env.GATSBY_API_PROJECT);
          return 'success'
        }
      } catch (e) {
        return e.code;
      }
    }
  };

  const handlePasswordResetRequest = async (email) => {
    try {
      const response = await fetch(`${process.env.GATSBY_API_ENDPOINT}/${process.env.GATSBY_API_PROJECT}/auth/password/request`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          reset_url: `${document.location.origin}/${process.env.GATSBY_PASSWORD_RESET_PATH}`,
        })
      });
      return await response.json();
    } catch (e) {
      console.log('Password Reset Request Failed', e);
    }
  };

  const handlePasswordReset = async (password, token) => {
    try {
      const response = await fetch(`${process.env.GATSBY_API_ENDPOINT}/${process.env.GATSBY_API_PROJECT}/auth/password/reset`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          password
        })
      });
      return await response.json();
    } catch (e) {
      console.log('Password Reset Failed', e);
    }
  };

  const handleLogout = async (cb) => {
    await client.logout();
    setCurrentUser(null);
    setLoggedIn(false);
    window.sessionStorage.removeItem(LOCAL_STORAGE_KEY);
    if (cb) {
      cb();
    }
  };

  return <ApiContext.Provider
    value={{
      client,
      handleLogin,
      handleLogout,
      handlePasswordResetRequest,
      handlePasswordReset,
      authChecked,
      isLoggedIn,
      currentUser,
      projectDetails,
      setProjectName,
    }}>
    <Helmet>
      <link rel='preconnect' href={process.env.GATSBY_API_ENDPOINT}/>
    </Helmet>
    {children}
  </ApiContext.Provider>
};